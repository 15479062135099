<template>
  <div class="card card-custom">
    <div class="card-header">
      <div class="card-title">
        <h3 class="card-label">{{ $t('user_details.file_management') }}</h3>
      </div>
      <div class="card-toolbar">
        <button 
          type="button" 
          class="btn btn-primary mr-2"
          @click="addNewFile"
          :disabled="isLoading"
        >
          <i class="flaticon2-plus fa-1x"></i> {{ $t('commons.add_new_file') }}
        </button>

        <!--begin::Dropdown-->
        <DatatableDropdown />
        <!--end::Dropdown-->

        <button 
          type="button" 
          class="btn btn-secondary font-weight-bold text-primary"
          @click="initDtTable(false)"
          :disabled="isLoading"
        >
          <i class="flaticon2-reload text-primary fa-1x"></i> {{ $t('commons.refresh') }}
        </button>
      </div>
    </div>
    <div class="card-body">
      <div v-if="isLoading">
        <div class="text-center">{{ $t("commons.data_loading") }}...</div>
      </div>
      <table class="table table-striped table-bordered collapsed" style="width:100%" ref="kt_datatable" v-else>
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">{{ $t('commons.type') }}</th>
            <th scope="col">{{ $t('commons.file') }}</th>
            <th scope="col">{{ $t('commons.state') }}</th>
            <th scope="col">{{ $t('commons.authorized') }}</th>
            <th scope="col">{{ $t('commons.time_addition') }}</th>
            <th scope="col">{{ $t('commons.transaction') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in attachments" :key="index">
            <th class="font-weight-bolder">{{ item.id }}</th>
            <td>{{ $te(`service.${item.type}`) ? $t(`service.${item.type}`) : item.type }}</td>
            <td><a :href="item.url" target="_blank">{{ $t('commons.show_file') }}</a></td>
            <td> {{ $t(`service.${item.status}`) }} </td>
            <td>{{ item.agent }}</td>
            <td v-html="item.created_at"></td>
            <td class="text-center">
              <button type="button" @click="deleteAttachment(item.id)" class="btn btn-sm btn-clean btn-icon btn-icon-md" :title="$t('commons.delete')">
                <i class="la la-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <AddFileModal
      v-if="addFileModalVisible"
      :userId="`${$route.params.id}`"
      @onClose="onCloseAddFileModal"
      @onSuccess="onFileAddSuccess"
    />
  </div>
</template>
<script>
import $ from "jquery";
import { mapState } from "vuex";
import DatatableDropdown from '@/components/datatable/Dropdown.vue';
import AddFileModal from './file-management/AddFileModal';
import Swal from 'sweetalert2'

export default {
  name: "file_management",
  components: {
    DatatableDropdown,
    AddFileModal
  },
  data() {
    return {
      addFileModalVisible: false
    };
  },
  computed: {
    ...mapState({
      lang: (state) => state.global.lang,
      permissions: (state) => state.customer.permissions,
      isLoading: (state) => state.userdetails.isLoading,
      attachments: (state) => state.userdetails.attachments,
    }),
  },
  methods: {
    checkPermission(permission) {
      return this.permissions?.some(val => String(val.name) === String(permission));
    },
    initDtTable(isLoading = true) {
      const self = this;
      self.$nextTick(function () {

        self.$store.dispatch('userdetails/getAttachmentsAction', { id: self.$route.params.id, isLoading }).then(function() {
            const fileName = "attachments";
            $(self.$refs["kt_datatable"]).DataTable().destroy()
            $(self.$refs["kt_datatable"]).DataTable({
              responsive: true,
              dom: "Blfrtip",
              pagingType: 'full_numbers',
              buttons: ["copy", "csv", "excel", "pdf", "print"].map((el) => ({
                extend: el,
                filename: fileName,
              })),
              initComplete: function() {
                $('.dt-buttons').hide();
              },
              columnDefs: [
                { orderable: false, targets: -1 },
                { responsivePriority: 1, targets: 0 },
                { responsivePriority: 2, targets: -1 },
              ],
              order: [[0, "desc"]],
              pageLength: 10,
              lengthMenu: [
                [10, 25, 50, -1],
                [10, 25, 50, "All"],
              ],
              language: {
                url: `/assets/languages/datatable/${this.lang}.json`,
              },
            });
            $("#printExport").on("click", function (e) {
              e.preventDefault();
              $(".buttons-print").trigger("click");
            });
            $("#copyExport").on("click", function (e) {
              e.preventDefault();
              $(".buttons-copy").trigger("click");
            });
            $("#excelExport").on("click", function (e) {
              e.preventDefault();
              $(".buttons-excel").trigger("click");
            });
            $("#csvExport").on("click", function (e) {
              e.preventDefault();
              $(".buttons-csv").trigger("click");
            });
            $("#pdfExport").on("click", function (e) {
              e.preventDefault();
              $(".buttons-pdf").trigger("click");
            });
        });
      });
    },
    addNewFile() {
      this.addFileModalVisible = true;
    },
    onCloseAddFileModal() {
      this.addFileModalVisible = false;
    },
    onFileAddSuccess() {
      this.initDtTable(false);
    },
    deleteAttachment(attachmentId) {
      Swal.fire({
        title: this.$t('commons.are_you_sure'),
        text: this.$t('commons.wont_be_able_to_revert'),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t('commons.yes_delete_it'),
        cancelButtonText: this.$t('commons.cancel'),
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("userdetails/deleteAttachmentsAction", attachmentId)
            .then(() => {
              this.initDtTable(false);
            })
            .catch(() => {});
        }
      });
    }
    
  },
  created() {
    if (this.permissions?.length > 0 && !this.checkPermission("crm.users.show.attachments")) {
      this.$router.push("/no-permission");
      return;
    }
    this.initDtTable()
  },
};
</script>

<style lang="scss">
</style>
